import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultLineOa(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_default_line_oa', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListLineOa(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_line_oa', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineOa(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_line_oa', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLineOa(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_line_oa', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLineOa(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_line_oa', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLineOa(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/delete_line_oa', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatusLineOa(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/change_status_line_oa', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineOaUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_line_oa_user', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeLineOaUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/change_line_oa_user', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
